import React from 'react'
import { ConfigProvider } from 'antd'
import { DrawerStyles } from 'antd/es/drawer/DrawerPanel'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, createBrowserRouter, Route, RouterProvider, Routes } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import App from 'App'
import reportWebVitals from 'reportWebVitals'
import { store } from './redux/store'
import theme from './theme'
import AuthScan from '../src/tabs/scan'
import Scan from '../src/tabs/scan/home'

import './index.css'

const drawerStyles: DrawerStyles = {
  header: {
    borderBottom: 'none',
    fontSize: 13,
    fontWeight: 'bold',
  },
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <App></App>,
  },
  {
    path: '/scan/auth',
    element: <AuthScan></AuthScan>,
  },
  {
    path: '/scan/home',
    element: <Scan></Scan>,
  },
])
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ConfigProvider
    theme={theme}
    // drawer={{
    //   styles: drawerStyles,
    // }}
  >
    <Provider store={store}>
      <RouterProvider router={router}></RouterProvider>
    </Provider>
  </ConfigProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
