import { Button as AntButton, Modal } from 'antd'
import styled, { keyframes } from 'styled-components'

// Flashing Red animation

const flash = keyframes`
  0% { background-color: #ff0000; color: #007F7C; }
  50% {background-color: #10123d; color: #007F7C;}
  100% { background-color: #ff0000; color: #e5e7eb;}
`

const flashUploadScan = keyframes`
  0% { background-color: #ff0000; color: #fffeff; }
  50% {background-color: #10123d; color: #fffeff;}
  100% { background-color: #ff0000; color: #fffeff;}
`

const flashBirthDayLabel = keyframes`
  0% { background-color: #4794EF; color: #fffeff; }
  50% {background-color: #10123d; color: #fffeff;}
  100% { background-color: #4794EF; color: #fffeff;}
`

export const Section = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  background: transparent;
`

export const Span = styled.span`
  width: 100%;
  display: flex;
  gap: 8px;
  color: #e5e7eb;
  text-transform: capitalize;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const NameWrapper = styled.div`
  width: 100%;
  margin-top: 2px;
`

export const EmptyText = styled.span`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const PatientInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e5e7eb;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const PatientInfoItem = styled.div`
  font-size: 20px;
`
export const Button = styled.button`
  height: 40px;
  width: 100px;
`
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
`
export const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border-radius: 4px;
  border: 2px solid #2a2d50;
  background: #10123d;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 16px;
`
export const Text = styled.div`
  position: absolute;
  margin-top: -13px;
  margin-left: 21px;
  padding: 12px 16px;
  width: 97px;
  background: #050624;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #2a2d50;
  border-radius: 4px;
  font-weight: 700;
  font-size: 13px;
`
export const TextTitle = styled.div`
  width: 144px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #2a2d56;
  border-bottom: 1px solid #2a2d56;
`
export const FeedbackContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 12px;
`
export const SoapContent = styled.div`
  height: 100%;
  width: 100%;
  paddingtop: 25px;
  paddingleft: 10px;
  paddingright: 10px;
`
export const DayText = styled.div`
  width: fit-content;
  padding-left: 3px;
  padding-right: 3px;
  height: 22px;
  display: flex;
  justify-content: center;

  align-items: center;
  border-radius: 6px;
  background: #2a2d56;
  border: 1px solid #2a2d56;
  color: #fff;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const TextContent = styled.div`
  color: #e5e7eb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const DateWraapper = styled.div`
  width: 46px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #10123d;
  margin-bottom: 12.8px;
  border: 1px solid #10123d;
  border-radius: 4px;
  color: #e5e7eb;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const DayWrapper = styled.div`
  width: 46px;
  height: 17px;
  position: absolute;
  display: flex;
  justify-content: center;
  border-radius: 6px 6px 0px 0px;
  background: #2a2d56;
  aligh-items: center;
  color: #e5e7eb;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const CalenderWrapper = styled.div`
  width: 46px;
  height: 42px;
  background: transparent;
  margin-bottom: 20px;
  margin-top: 12px;
  color: #e5e7eb;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const MetricWrapper = styled.div`
  display: flex;
  gap: 11px;
  align-items: center;
  height: 48px;
  background: transparent;
  margin-top: 20px;
`
export const MetricCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #10123d;
  border-radius: 8px;
  border: 1px solid #343755;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #007f7c;
`
export const FlashingMetricCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #10123d;
  border-radius: 8px;
  border: 1px solid #343755;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  animation: ${flash} 1.5s infinite;
`
export const AgeBadge = styled.div`
  height: 30px;
  width: 30px;
  position: absolute;
  background: #007f7c;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 227px;
  top: 21px;
  margin-top: 8px;
  margin-left: -8px;
  z-index: 1;
  font-size: 12px;
  cursor: default;
`

export const MetricTitle = styled.span`
  color: #e5e7eb;
  font-weight: 700;
  font-size: 13px;
`
export const MetricValue = styled.span`
  padding-top: 4px;
  font-weight: 700;
  font-size: 14px;
`
export const TreatmentTypeContainer = styled.div`
  display: flex;
  gap: 19px;
  align-items: center;
  height: 50px;
  background: transparent;
  margin-top: 16px;
  margin-bottom: 8px;
`

export const TreatmentType = styled(AntButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 168px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  color: #e5e7eb;
  background: #007f7c;
  border: 1px solid #10123d;
  padding: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`

export const ButtonChildren = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: #007f7c;
  font-weight: 700;
`

// Scan Report

export const PopUp = styled(Modal)`
  min-width: 42% !important;
  height: 79%;
  background-color: #10123d;
  border-radius: 4px;
  padding: 20px;
  margin-left: 42%;
  margin-top: 20px;

  @media (min-width: 1450px) {
    width: 46% !important;
    margin-left: 44%;
  }

  @media (max-height: 800px) {
    margin-top: 4%;
  }

  @media (min-height: 1100px) {
    width: 52% !important;
    height: 79.5%;
    margin-left: 40%;
    margin-top: -50px;
  }
`
export const ScanReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #10123d;
  border-radius: 4px;
`
export const ScanReportContainer = styled.div`
  height: 727px;
  margin-top: 22px;
  @media (min-height: 1100px) {
    height: 995px;
  }
`

export const LoadingContainer = styled.div`
  height: 752px !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-height: 1100px) {
    height: 1060px !important;
  }
`
export const Container: any = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 14px;
  position: relative;
`

export const LeftBar = styled.div`
  position: relative;
  border-bottom: 25px solid #007f7c;
  border-left: 25px solid transparent;
  background-color: transparent;
`

export const LeftBarText = styled.div`
  position: absolute;
  top: 7px;
  left: 0px;
  font-weight: 700;
  font-size: 12px;
  background-color: none;
`

export const LeftBarContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0px;
  z-index: 1;
  margin-top: -2px;
`

export const RightBarContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0px;
  z-index: 1;
  margin-top: -2px;
`

export const RightBar = styled.div`
  position: relative;
  border-bottom: 25px solid #007f7c;
  border-right: 25px solid transparent;
  background-color: transparent;
`

export const RightBarText = styled.div`
  position: absolute;
  top: 7px;
  right: 0px;
  font-weight: 700;
  font-size: 12px;
  background-color: none;
`

export const HexagonContainer = styled.div`
  position: absolute;
  margin: 0px -64.5px 2.5px -64.5px;
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

export const Hexagon = styled.div`
  width: 32px;
  height: 32px;
  background-color: #0f1031;
  position: absolute;
  right: -80px;
  // margin-top: -0.5px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 10px;
`

export const HexagonLabel = styled.div`
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
export const LeftCircle = styled.div`
  background: #32344b;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  // margin-right: 25px;
  margin-top: 10px;
`

export const RightCircle = styled.div`
  background: #32344b;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  // margin-left: 20px;
  margin-top: 10px;
`
export const Footer = styled.div`
  height: 71px;
  position: absolute;
  top: 687px;
  // bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  background: #2a2d56;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  @media (min-height: 1100px) {
    margin-top: 66px;
    top: 951px;
  }
`

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-left: 24px;
`
export const ScanDayAndDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
`
export const Day = styled.span`
  font-size: 18px;
  font-weight: 700;
`

export const MonthAndDate = styled.span`
  font-size: 16px;
  font-weight: 400;
  margin-top: -8px;
`

export const ScoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-right: 20px;
`
export const Score = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33px;
  min-width: 73px;
  font-weight: 700;
  font-size: 18px;
  padding: 5px 16px;
  border-radius: 4px;
  border: 2px solid #007f7c;
`
export const PageToggle = styled.div`
  display: flex;
  gap: 11px;
`
export const Arrows = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #007f7c;
  border-radius: 50%;
  width: 38px;
  height: 38px;
`

export const ScanDueIn = styled.div`
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  left: 50%;
  min-width: 500px;
  transform: translateX(-45%);
  // right: 27.5%;
  margin-bottom: 20px;
`
export const ScanDueIdentifier = styled(AntButton)`
  width: 100%;
  height: 100%;
  border: 1px solid #e5e7eb;
  // margin-top: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #10123d;
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  animation: ${flashUploadScan} 1.5s infinite;
`

export const BirthdaySvg = styled.img`
  height: 20px;
  width: 20px;
  margin-top: -2px;
`

export const BirthDayIdentifier = styled(AntButton)`
  width: 100%;
  height: 100%;
  border: 1px solid #e5e7eb;
  // margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: #10123d;
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  animation: ${flashBirthDayLabel} 1.5s infinite;
`
