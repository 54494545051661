import { differenceInCalendarDays } from 'date-fns'

// Function to calculate if the birthday is 5 days away or 5 days past
export function shouldShowBday(birthDate: Date) {
  const today = new Date()

  const currentYear = today.getFullYear()

  const thisYearBirthday = new Date(currentYear, birthDate.getMonth(), birthDate.getDate())

  // Calculate the difference in days
  const diffInDays = differenceInCalendarDays(thisYearBirthday, today)

  // Check the status
  if (diffInDays >= -5 && diffInDays <= 5) {
    return true
  } else {
    return false
  }
}
