import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_URL, AppConstant } from 'AppConstant'
import { APIStatus } from '../types'

interface Visit {
  id: string
  appointmentTime: string
  timezone: string
  status: string
  checkinTime: string | null
  serviceStartTime: string | null
  serviceEndTime: string | null
  zoomLink: string | null
  clinicId: string
  chiropractorId: string | null
  bedId: string | null
  patientId: string
  isVirtual: boolean
  screenerId: string | null
  isScreening: boolean
  createdAt: string
  updatedAt: string
  patient: Patient
  scanDue: boolean
}

interface Patient {
  id: string
  stripeCustomerId: string
  screeningStatus: string
  treatmentType: string | null
  isQuestionareCompleted: boolean
  screenerFeedback: string | null
  screeningSessionRecording: string | null
  createdAt: string
  updatedAt: string
  user: User
}

interface User {
  id: string
  firstname: string
  signupThrough: string
  referralCode: string | null
  lastname: string
  phone: string
  unverifiedPhone: string | null
  dob: string
  email: string | null
  unverifiedEmail: string | null
  externalUserId: string | null
  emailVerificationId: string | null
  welcomeEmailSend: boolean
  textConsent: boolean
  profilePic: string
  screeningStatus: string
  gender: string | null
  isDeleted: boolean
  createdAt: string
  updatedAt: string
}

interface IInitialstate {
  scanList: Array<Visit> | null
  scanListStatus: APIStatus
}

const initialState: IInitialstate = {
  scanList: null,
  scanListStatus: APIStatus.idle,
}

export const getTodayScanListAsync = createAsyncThunk(
  'scan/getTodayScanList',
  async ({ timezone }: { timezone: string }, thunkAPI) => {
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN_SCAN_AUTH)
    const clinicId = localStorage.getItem(AppConstant.CLINIC_ID)

    let response = await axios
      .get(`${API_URL}/visit/scan-users?timezone=${timezone}&clinicId=${clinicId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
        },
      })
      .then((res) => res)
      .catch((err) => err.response)

    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue(response.data.data)
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)

const scanSlice = createSlice({
  name: 'scan',
  initialState,
  reducers: {
    // setOTPSent: (state, action) => {
    //   state.isOTPSent = action.payload
    // },
    // setClinicId: (state, action) => {
    //   state.clinicId = action.payload
    // },
    updateScanList: (state, action) => {
      if (state.scanList) {
        state.scanList = state.scanList?.filter((s) => s.id !== action.payload.visitId)
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(getTodayScanListAsync.fulfilled, (state, action) => {
      state.scanListStatus = APIStatus.fulfilled
      state.scanList = action.payload
    })
    builder.addCase(getTodayScanListAsync.rejected, (state, action) => {
      state.scanListStatus = APIStatus.rejected
    })
    builder.addCase(getTodayScanListAsync.pending, (state, action) => {
      state.scanListStatus = APIStatus.pending
    })
  },
})

export const { updateScanList } = scanSlice.actions
export default scanSlice.reducer
