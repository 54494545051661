import { useEffect, useRef, useState } from 'react'
import { Button, Input } from 'antd'
import axios from 'axios'
import styled from 'styled-components'
import { API_URL, AppConstant } from 'AppConstant'
import { useAuth } from 'hooks/useAuth'
import FullScreen from 'images/fullscreen.svg'
import Bed from '../images/bed.svg'
import KLogo from '../images/initialLogo.svg'
import Logout from '../images/logout.svg'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #050624;
`

const LoginBox = styled.div`
  width: 384px;
  height: auto;
  margin-bottom: 150px;
`
const LoginDetails = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
const Logo = styled.img`
  margin-bottom: 66px;
`

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: #007f7c;
  font-size: 20px;
  font-style: normal;
  display: flex;
  align-item: center;
  margin-bottom: 16px;
  font-weight: 800;
  line-height: 130%;
`

const UserLogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-item: center;
`
const UserLogo = styled.img`
  margin-bottom: 8px;
`

const InputWrapper = styled.div`
  width: 100%;
`

const Label = styled.label`
  color: #e5e7eb;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 2px;
`

const ErrorWrapper = styled.div`
  width: 100%;
  color: #e33939;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  display: inline-block;
  margin-top: 5px;
  margin-left: 2px;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 29px;
`
export enum ErrorStatus {
  error = 'error',
  warning = 'warning',
  emptyString = '',
}

export default function Setup({ onBedSetupComplete }: any) {
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const { signOut } = useAuth()
  const [buttonLoading, setButtonLoading] = useState(false)
  const [errorStatus, setErrorStatus] = useState<ErrorStatus>(ErrorStatus.emptyString)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const tabRef = useRef<any>(null)

  useEffect(() => {
    document.addEventListener('focusout', function (e) {
      window.scrollTo(0, 0)
    })

    return () => {
      document.removeEventListener('focusout', function (e) {
        window.scrollTo(0, 0)
      })
    }
  })

  async function getBed() {
    setButtonLoading(true)
    const event = document
    if (code === '') {
      setError('empty')
      setButtonLoading(false)
      setErrorStatus(ErrorStatus.error)
    } else {
      if (event) {
        event.addEventListener('keypress', function (e) {
          if (e.key === 'Enter') {
            window.scrollTo(0, 0)
          }
        })
      }
      let response = await axios
        .get(API_URL + '/bed/code/' + code, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(AppConstant.ACCESS_TOKEN)}`,
            'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
          },
        })
        .then((res) => res)
        .catch((err) => err.response)

      if (response && response.data.code === 'success' && response.data.data) {
        localStorage.setItem(AppConstant.LOCAL_BED, JSON.stringify(response.data.data))
        onBedSetupComplete(response.data.data)
      } else if (response && response.data.code === 'bed_not_found') {
        setError('no_bed')
        setButtonLoading(false)
      } else {
        setButtonLoading(false)
      }
    }
  }

  const enterFullscreen = () => {
    const elem: any = tabRef.current
    const doc: any = document
    if (elem) {
      if (isFullScreen) {
        if (doc.exitFullscreen) {
          doc.exitFullscreen()
          setIsFullScreen(false)
        } else if (doc.webkitExitFullscreen) {
          doc.webkitExitFullscreen()
          setIsFullScreen(false)
        } else if (doc.msExitFullscreen) {
          doc.msExitFullscreen()
          setIsFullScreen(false)
        }
      } else {
        if (elem.requestFullscreen) {
          elem.requestFullscreen()
          setIsFullScreen(true)
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen()
          setIsFullScreen(true)
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen()
          setIsFullScreen(true)
        }
      }
    }
  }

  return (
    <Container ref={tabRef}>
      <div
        onClick={() => {
          signOut().finally(() => {})
        }}
        style={{
          background: 'transparent',
          position: 'fixed',
          top: 78,
          right: '63px',
          color: '#007F7C',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <img src={Logout} style={{ marginRight: '8px' }}></img>
        Logout
      </div>
      {/* <div
        onClick={() => {
          enterFullscreen()
        }}
        style={{
          position: 'fixed',
          top: 30,
          right: '44px',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <img src={FullScreen}></img>
      </div> */}

      <LoginBox>
        <LogoWrapper>
          <Logo src={KLogo}></Logo>
        </LogoWrapper>
        <LoginDetails
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              getBed()
            }
          }}
        >
          <InputWrapper>
            <Label>Table Code</Label>
            <Input
              value={code}
              inputMode="numeric"
              onChange={(e) => {
                setCode(e.target.value)
                setError('')
                setErrorStatus(ErrorStatus.emptyString)
              }}
              status={errorStatus}
              placeholder="Enter code"
            ></Input>
            {error === 'empty' && <ErrorWrapper>Please enter bed code !</ErrorWrapper>}
            {error === 'no_bed' && <ErrorWrapper>Bed not found !</ErrorWrapper>}
            <ButtonWrapper>
              <Button type="primary" style={{ width: '100%' }} onClick={getBed}>
                Set Up
              </Button>
            </ButtonWrapper>
          </InputWrapper>
        </LoginDetails>
      </LoginBox>
    </Container>
  )
}
