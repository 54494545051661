import { Modal, Button as AntButton } from 'antd'
import styled, { keyframes } from 'styled-components'

export const Content = styled.div`
  height: 80vh;
  width: 100%;
  background: transparent;
  display: flex;
`
export const HeaderText = styled.div`
  color: #e5e7eb;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
export const TimeStamp = styled.div`
  width: 110px;
  height: 31px;
  border-radius: 4px;
  border: 1px solid #343755;
  color: #e5e7eb;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 8px;
`

export const Container = styled.div`
  height: 40%;
  min-height: 350px;
  width: 100%;
  background: #10123d;
  border-radius: 4px;
`
export const NameWrapper = styled.div`
  width: 100%;
`
export const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border-radius: 4px;
  border: 2px solid #2a2d50;
  background: #10123d;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 16px;
`

export const Text = styled.div`
  position: absolute;
  margin-top: -13px;
  margin-left: 21px;
  padding: 12px 16px;
  width: 97px;
  background: #050624;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #2a2d50;
  border-radius: 4px;
  font-weight: 700;
  font-size: 13px;
`

export const NameText = styled.div`
  color: #e5e7eb;
  text-transform: capitalize;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const Span = styled.span`
  width: 100%;
  display: flex;
  gap: 8px;
  color: #e5e7eb;
  text-transform: capitalize;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 86px;
  background: #2a2d56;
  border-radius: 4px;
  margin-top: 30px;
`
export const NoScansToUpload = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 100px;
  font-size: 18px;
  font-weight: 700;
  background: none;
  border-radius: 4px;
  padding: 20px;
`
export const CenteredContainer = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
`
