import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Avatar, Button, Card, Flex, Input, Spin } from 'antd'
import { format } from 'date-fns'
import reload from 'images/reload.svg'
import NotesDrawer from 'tabs/NotesDrawer/notesDrawer'
import { convertUtcToEst } from 'utils/DateAndTimeFormat'
import { calculateAge } from 'utils/Dob'
import {
  Wrapper,
  PatientInfo,
  Span,
  CardWrapper,
  Text,
  DayText,
  NameText,
  EmptyText,
  DateWraapper,
  DayWrapper,
  FlexWrapper,
  CalenderWrapper,
  Retry,
  RetryLoading,
} from './NotesTab.styled'
import { useLobbyPolling } from '../../hooks/useLobbyPolling'
import Notes from '../../images/notes.svg'
import notes, {
  setCurrentNote,
  getNotesByStatusAsync,
  updateNotesAsync,
  getNotesAsync,
  autoUpdateNotesAsync,
  retryNotesAsync,
} from '../../redux/notes'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { APIStatus } from '../../redux/types'

export default function CurrentTab() {
  const dispatch = useAppDispatch()
  const pendingNotes = useAppSelector((state) => state.notes.notes)
  const selectedNote = useAppSelector((state) => state.notes.currentNote)
  const notesStatus = useAppSelector((state) => state.notes.notesStatus)
  const retryNotesStatus = useAppSelector((state) => state.notes.retryNotesStatus)
  const [retryingNote, setRetryingNote] = useState('')
  const patientNotes = useAppSelector((state) => state.notes.patientNotes)
  const currentNoteUpdateStatus = useAppSelector((state) => state.notes.currentNoteUpdateStatus)
  const [initialNote, setInitialNote] = useState(true)
  const debounceTimer: any = useRef(null)

  useLobbyPolling()

  const [localNoteObject, setLocalNoteObject] = useState<any>(null)

  const formatDate = useCallback((time: string, isMonth: boolean) => {
    const stamp = new Date(time)
    const estTime = convertUtcToEst(stamp)

    if (isMonth === true) {
      return format(estTime, 'MMM')
    } else {
      return format(estTime, 'dd')
    }
  }, [])

  const currentPatientNote = useMemo(() => {
    if (selectedNote?.patient?.id) {
      return patientNotes[selectedNote?.patient?.id]
    } else {
      return null
    }
  }, [patientNotes, selectedNote])

  useEffect(() => {
    setLocalNoteObject({
      s: selectedNote?.subjective,
      o: selectedNote?.objective,
      a: selectedNote?.assessment,
      p: selectedNote?.plan,
    })
  }, [selectedNote])

  const currentPatientId = selectedNote?.patient?.id

  useEffect(() => {
    if (currentPatientId) {
      dispatch(getNotesAsync(currentPatientId))
    }
  }, [currentPatientId])

  useEffect(() => {
    dispatch(getNotesByStatusAsync())
  }, [])

  const updateSoapNotes = useCallback(() => {
    dispatch(
      updateNotesAsync({
        updatedNotes: {
          ...selectedNote,
          subjective: localNoteObject.s,
          objective: localNoteObject.o,
          assessment: localNoteObject.a,
          plan: localNoteObject.p,
          status: 'accepted',
        },
        notes: pendingNotes,
      }),
    )
  }, [selectedNote, dispatch, localNoteObject])

  useEffect(() => {
    if (pendingNotes && initialNote) {
      const currentFirstNote = pendingNotes[0]
      dispatch(setCurrentNote(currentFirstNote))
      setInitialNote(false)
    }
  }, [pendingNotes])

  const autoSaveNotes = useCallback(
    (e: any, config: { s?: boolean; o?: boolean; a?: boolean; p?: boolean }) => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current)
      }

      debounceTimer.current = setTimeout(() => {
        const { s, o, a, p } = config
        dispatch(
          autoUpdateNotesAsync({
            updatedNotes: {
              ...selectedNote,
              subjective: s ? e.target.value : localNoteObject?.s,
              objective: o ? e.target.value : localNoteObject?.o,
              assessment: a ? e.target.value : localNoteObject?.a,
              plan: p ? e.target.value : localNoteObject?.p,
              status: 'pending',
            },
          }),
        )
      }, 1000)
    },
    [localNoteObject],
  )

  useEffect(() => {
    return () => clearTimeout(debounceTimer.current)
  }, [])

  console.log(selectedNote?.createdAt, 'selected note created at')

  // if (notesStatus === APIStatus.pending) {
  //   return (
  //     <div
  //       style={{
  //         width: '100%',
  //         height: '100%',
  //         display: 'flex',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //       }}
  //     >
  //       <Spin style={{ marginBottom: '130px' }}></Spin>
  //     </div>
  //   )
  // } else
  if (pendingNotes.length > 0) {
    return (
      <Flex gap={60}>
        <Wrapper>
          <Flex
            vertical
            style={{
              width: '100%',
              marginBottom: '15px',
              height: '100%',
              touchAction: 'none',
              WebkitUserSelect: 'none',
            }}
          >
            <Flex
              vertical
              gap={12}
              style={{
                width: '100%',
                marginBottom: '12px',
                overflowY: 'auto',
                height: '100%',
              }}
            >
              <Flex gap={8} vertical style={{ padding: '2px', width: '100%' }}>
                {pendingNotes?.map((n) => (
                  <Card
                    style={{
                      border: selectedNote?.id === n.id ? ' 1px solid #FFFEFF' : '1px solid #2A2D50',
                      // height: '66px',
                      display: 'flex',
                      flex: 1,
                      width: '100%',
                      alignItems: 'center',
                    }}
                    cover={
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          alignItems: 'center',
                          marginLeft: 20,
                          paddingTop: 9,
                          paddingBottom: 8,
                          justifyContent: 'space-between',
                        }}
                      >
                        <Avatar size={40} src={n.patient?.user?.profilePic}></Avatar>
                        <NameText>{n.patient?.user?.firstname + ' ' + n.patient?.user?.lastname}</NameText>
                        {selectedNote?.id === n.id && (
                          <div style={{ position: 'absolute', right: 30 }}>
                            <Button
                              children={'Approve'}
                              loading={currentNoteUpdateStatus === APIStatus.pending}
                              onClick={() => {
                                updateSoapNotes()
                              }}
                              type="primary"
                              style={{
                                height: '30px',
                                width: '130px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: 13,
                              }}
                            ></Button>
                          </div>
                        )}
                      </div>
                    }
                    onClick={() => {
                      dispatch(setCurrentNote(n))
                    }}
                    key={n.id}
                  ></Card>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Wrapper>
        <Wrapper>
          {selectedNote && (
            <>
              <Flex
                gap={17}
                style={{
                  flexDirection: 'row-reverse',
                  alignContent: 'flex-end',
                  flex: '1',
                  marginBottom: '15px',
                  touchAction: 'none',
                  WebkitUserSelect: 'none',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    touchAction: 'none',
                    WebkitUserSelect: 'none',
                  }}
                >
                  <CalenderWrapper>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        background: '#2A2D56',
                        borderTopLeftRadius: 6,
                        borderTopRightRadius: 6,
                        padding: '3px 9px',
                      }}
                    >
                      {selectedNote?.createdAt ? formatDate(selectedNote.createdAt, true) : ''}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        background: '#10123d',
                        padding: '6px 15px',
                        borderBottomLeftRadius: 6,
                        borderBottomRightRadius: 6,
                      }}
                    >
                      {selectedNote?.createdAt ? formatDate(selectedNote.createdAt, false) : ''}
                    </div>
                  </CalenderWrapper>
                  {/* <DayWrapper>
                    {selectedNote?.createdAt ? format(new Date(selectedNote.createdAt), 'MMM') : ''}
                  </DayWrapper>
                  <DateWraapper>
                    <span style={{ marginTop: 10 }}>
                      {selectedNote?.createdAt ? format(new Date(selectedNote.createdAt), 'dd') : ''}
                    </span>
                  </DateWraapper> */}
                  <NotesDrawer notes={currentPatientNote?.notes}></NotesDrawer>
                </div>

                <Flex vertical gap={8} style={{ width: '100%', touchAction: 'none', WebkitUserSelect: 'none' }}>
                  {/* <img src={Cross}></img> */}

                  <CardWrapper style={{ marginTop: 12 }}>
                    <div
                      style={{
                        zIndex: '20',
                        background: '#10123d',
                        position: 'absolute',
                        top: '1px',
                        left: '2px',
                      }}
                    >
                      <Text>Subjective</Text>
                    </div>
                    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                      <Input.TextArea
                        style={{
                          height: '100%',
                          resize: 'none',
                          paddingTop: '25px',
                        }}
                        value={localNoteObject?.s || ''}
                        onChange={(e) => {
                          setLocalNoteObject({
                            ...localNoteObject,
                            s: e.target.value,
                          })
                          autoSaveNotes(e, { s: true })
                        }}
                      ></Input.TextArea>
                      {retryNotesStatus === APIStatus.pending && retryingNote === 'subjective' ? (
                        <RetryLoading src={reload}></RetryLoading>
                      ) : (
                        <Retry
                          src={reload}
                          onClick={() => {
                            dispatch(retryNotesAsync({ notesId: selectedNote?.id, noteToRetry: 'subjective' }))
                            setRetryingNote('subjective')
                          }}
                        ></Retry>
                      )}
                    </div>
                  </CardWrapper>

                  <CardWrapper>
                    <div
                      style={{
                        zIndex: '20',
                        background: '#10123d',
                        position: 'absolute',
                        top: '1px',
                        left: '2px',
                      }}
                    >
                      <Text>Objective</Text>
                    </div>
                    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                      <Input.TextArea
                        style={{
                          height: '100%',
                          resize: 'none',
                          paddingTop: '25px',
                        }}
                        value={localNoteObject?.o || ''}
                        onChange={(e) => {
                          setLocalNoteObject({
                            ...localNoteObject,
                            o: e.target.value,
                          })
                          autoSaveNotes(e, { o: true })
                        }}
                      ></Input.TextArea>
                      {retryNotesStatus === APIStatus.pending && retryingNote === 'objective' ? (
                        <RetryLoading src={reload}></RetryLoading>
                      ) : (
                        <Retry
                          src={reload}
                          onClick={() => {
                            dispatch(retryNotesAsync({ notesId: selectedNote?.id, noteToRetry: 'objective' }))
                            setRetryingNote('objective')
                          }}
                        ></Retry>
                      )}
                    </div>
                  </CardWrapper>
                  <CardWrapper>
                    <div
                      style={{
                        zIndex: '20',
                        background: '#10123d',
                        position: 'absolute',
                        top: '1px',
                        left: '2px',
                      }}
                    >
                      <Text>Assessment</Text>
                    </div>
                    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                      <Input.TextArea
                        style={{
                          height: '100%',
                          resize: 'none',
                          paddingTop: '25px',
                        }}
                        value={localNoteObject?.a || ''}
                        onChange={(e) => {
                          setLocalNoteObject({
                            ...localNoteObject,
                            a: e.target.value,
                          })
                          autoSaveNotes(e, { a: true })
                        }}
                      ></Input.TextArea>
                      {retryNotesStatus === APIStatus.pending && retryingNote === 'assessment' ? (
                        <RetryLoading src={reload}></RetryLoading>
                      ) : (
                        <Retry
                          src={reload}
                          onClick={() => {
                            dispatch(retryNotesAsync({ notesId: selectedNote?.id, noteToRetry: 'assessment' }))
                            setRetryingNote('assessment')
                          }}
                        ></Retry>
                      )}
                    </div>
                  </CardWrapper>
                  <CardWrapper>
                    <div
                      style={{
                        zIndex: '20',
                        background: '#10123d',
                        position: 'absolute',
                        top: '1px',
                        left: '2px',
                      }}
                    >
                      <Text>Plan</Text>
                    </div>
                    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                      <Input.TextArea
                        style={{
                          height: '100%',
                          resize: 'none',
                          paddingTop: '25px',
                        }}
                        value={localNoteObject?.p || ''}
                        onChange={(e) => {
                          setLocalNoteObject({
                            ...localNoteObject,
                            p: e.target.value,
                          })
                          autoSaveNotes(e, { p: true })
                        }}
                      ></Input.TextArea>
                      {retryNotesStatus === APIStatus.pending && retryingNote === 'plan' ? (
                        <RetryLoading src={reload}></RetryLoading>
                      ) : (
                        <Retry
                          src={reload}
                          onClick={() => {
                            dispatch(retryNotesAsync({ notesId: selectedNote?.id, noteToRetry: 'plan' }))
                            setRetryingNote('plan')
                          }}
                        ></Retry>
                      )}
                    </div>
                  </CardWrapper>
                </Flex>
              </Flex>
            </>
          )}
        </Wrapper>
      </Flex>
    )
  } else {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '130px',
          }}
        >
          <EmptyText>Look at you, Doc! No more notes!</EmptyText>
        </div>
      </div>
    )
  }
}
