import { Flex } from 'antd'
import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  background: transparent;
  touch-action: none;
  webkit-user-select: none;
`

export const Span = styled.span`
  width: 100%;
  display: flex;
  text-transform: capitalize;
  color: #e5e7eb;
  font-family: 'WixMadeforDisplay';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const SoapContent = styled.div`
  height: 100%;
  width: 100%;
  paddingtop: 25px;
  paddingleft: 10px;
  paddingright: 10px;
`

export const PatientInfo = styled.div`
  color: #e5e7eb;
  text-transform: capitalize;
  font-family: 'WixMadeforDisplay';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const Button = styled.button`
  height: 40px;
  width: 100px;
`
export const ButtonContainer = styled.div`
  width: 406px;
  height: 56px;
  display: flex;
  margin-top: 400px;
  gap: 10px;
`
export const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #2a2d50;
  background: #10123d;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 16px;
`

export const Retry = styled.img`
  width: 18px;
  height: 18px;
  background: #10123d;
  border-radius: 10px;
  position: absolute;
  top: 9%;
  left: 97%;
  transform: translate(-50%, -50%);
`
export const RetryLoading = styled.img`
  width: 18px;
  height: 18px;
  background: #10123d;
  border-radius: 10px;
  position: absolute;
  top: 9%;
  left: 97%;
  transform: translate(-50%, -50%);
  animation: rotate 1.2s linear infinite;
  translateZ(0);
  @keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }
`

export const Text = styled.div`
  position: absolute;
  margin-top: -13px;
  margin-left: 20px;
  padding: 12px 16px;
  width: 97px;
  background: #050624;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #2a2d50;
  border-radius: 4px;
  font-weight: 700;
  font-size: 13px;
`
export const DayText = styled.div`
  width: fit-content;
  height: 22px;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #2a2d56;
  border: 1px solid #2a2d56;
  color: #fff;
  font-family: 'WixMadeforDisplay';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const TextContent = styled.div`
  color: #e5e7eb;
  font-family: 'WixMadeforDisplay';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const NameText = styled.div`
  color: #e5e7eb;
  text-transform: capitalize;
  font-family: 'WixMadeforDisplay';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const EmptyText = styled.span`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
export const DateWraapper = styled.div`
  width: 46px;
  height: 40px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #10123d;
  margin-bottom: 12.8px;
  border: 1px solid #10123d;
  border-radius: 4px;
  color: #e5e7eb;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`
export const DayWrapper = styled.div`
  width: 40px;
  height: 17px;
  position: absolute;
  display: flex;
  justify-content: center;
  border-radius: 6px 6px 0px 0px;
  background: #2a2d56;
  aligh-items: center;
  color: #e5e7eb;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`
export const FlexWrapper = styled(Flex)`
  &::-webkit-scrollbar {
    display: none;
  }
`

export const CalenderWrapper = styled.div`
  width: 46px;
  height: 40px;
  background: transparent;
  margin-bottom: 22px;
  margin-top: 12px;
  color: #e5e7eb;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`
