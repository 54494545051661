import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_URL, AppConstant } from 'AppConstant'
import { APIStatus } from '../types'
interface IProfile {
  dob: string
  email: string
  firstname: string
  lastname: string
  gender: string
  id: string
  phone: string
  profilPic: string
  clinics: [
    {
      id: string
      name: string
    },
  ]
}
interface IInitialstate {
  profile: IProfile | null
  profileStatus: APIStatus
  isOTPSent: boolean
  phone: string
}
const initialState: IInitialstate = {
  profile: null,
  profileStatus: APIStatus.idle,
  isOTPSent: false,
  phone: '',
}

export const getProfile = createAsyncThunk('me/getProfile', async (_, thunkAPI) => {
  const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)

  let response = await axios
    .get(`${API_URL}/auth/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
      },
    })
    .then((res) => res)
    .catch((err) => err.response)

  if (response.data && response.data.code === 'success') {
    return thunkAPI.fulfillWithValue(response.data.data)
  } else {
    return thunkAPI.rejectWithValue(response.data.message)
  }
})

const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    setOTPSent: (state, action) => {
      state.isOTPSent = action.payload
    },
    setPhoneNumber: (state, action) => {
      state.phone = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profileStatus = APIStatus.fulfilled
      state.profile = action.payload as IProfile
    })
    builder.addCase(getProfile.rejected, (state, action) => {
      state.profileStatus = APIStatus.rejected
    })
    builder.addCase(getProfile.pending, (state, action) => {
      state.profileStatus = APIStatus.pending
    })
  },
})

export const { setOTPSent, setPhoneNumber } = meSlice.actions
export default meSlice.reducer
