import { createSlice } from '@reduxjs/toolkit'

interface DoctorState {
  userId: string
  firstname: string
  lastname: string
}

const initialState: DoctorState = {
  userId: '',
  firstname: '',
  lastname: '',
}

const bedSlice = createSlice({
  name: 'doctor',
  initialState,
  reducers: {
    setDoctor: (state, action) => {
      state.userId = action.payload.userId
      state.firstname = action.payload.firstname
      state.lastname = action.payload.lastname
    },
  },
})

export const { setDoctor } = bedSlice.actions
export default bedSlice.reducer
