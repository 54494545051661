import { useCallback, useEffect, useRef, useState } from 'react'
import AudioRecorder from 'audio-recorder-polyfill'
import { resetStartChiropracticStatus, resetStopChiropracticStatus } from '../redux/lobby'
import { useAppDispatch, useAppSelector } from '../redux/store'
import { APIStatus } from '../redux/types'

// export function useRecordingHook() {
//   const [permission, setPermission] = useState(false)
//   const [stream, setStream] = useState<any>(null)
//   const [recordingStatus, setRecordingStatus] = useState('inactive')
//   const [audioChunks, setAudioChunks] = useState<Array<any>>([])
//   const [audio, setAudio] = useState<any>(null)

//   const startChiropracticStatus = useAppSelector((state) => state.lobby.startChiropracticStatus)
//   const stopChiropracticStatus = useAppSelector((state) => state.lobby.stopChiropracticStatus)
//   const currentPatient = useAppSelector((state) => state.lobby.currentPatient)

//   const mediaRecorder = useRef<any>(null)

//   useEffect(() => {
//     if (startChiropracticStatus === APIStatus.fulfilled && recordingStatus === 'inactive') {
//       startRecording()
//     }
//   }, [startChiropracticStatus])

//   useEffect(() => {
//     if (
//       (stopChiropracticStatus === APIStatus.fulfilled || stopChiropracticStatus === APIStatus.rejected) &&
//       recordingStatus === 'recording'
//     ) {
//       stopRecording()
//     }
//   }, [stopChiropracticStatus])

//   // const mimeType = 'audio/webm'
//   //  const mimeType = 'audio/mp4'

//   //   const callbackFun =  useCallback(
//   //     (event: any) => {
//   //       if (mediaRecorder && mediaRecorder.current) {
//   //         let temp = [...audioChunks]
//   //         setAudioChunks
//   //       }
//   //     },
//   //     [audioChunks, setAudioChunks, mediaRecorder],
//   //   )

//   const startRecording = async () => {
//     setRecordingStatus('recording')
//     //create new Media recorder instance using the stream
//     const media = new MediaRecorder(stream)
//     //set the MediaRecorder instance to the mediaRecorder ref
//     mediaRecorder.current = media
//     //invokes the start method to start the recording process
//     mediaRecorder.current.start()
//     let localAudioChunks: Array<any> = []
//     mediaRecorder.current.ondataavailable = (event: any) => {
//       if (typeof event.data === 'undefined') return
//       if (event.data.size === 0) return
//       localAudioChunks.push(event.data)
//     }
//     setAudioChunks(localAudioChunks)
//   }

//   function handleDataAvailable(patient: any, audioChunks: any) {
//     console.log('Uploadin file')
//     console.log(patient)
//     console.log(patient?.notesUploadUrl)

//     if (!patient?.notesUploadUrl) {
//       return
//     }
//     const blob = new Blob(audioChunks, { type: mimeType })
//     // const formData = new FormData()
//     // formData.append('audio', blob)

//     // axios.put(patient?.notesUploadUrl,blob,{

//     // })

//     // console.log(blob)

//   }

//   function stopRecording() {
//     setRecordingStatus('inactive')
//     //stops the recording instance

//     if (mediaRecorder && mediaRecorder.current) {
//       mediaRecorder.current.stop()

//       console.log('STOPPP FUNCTION file')
//       console.log(currentPatient)

//       mediaRecorder.current.onstop = () => {
//         //creates a blob file from the audiochunks data
//         const audioBlob = new Blob(audioChunks, { type: mimeType })
//         handleDataAvailable(currentPatient, audioChunks)
//         //creates a playable URL from the blob file.
//         // const audioUrl = URL.createObjectURL(audioBlob)
//         // setAudio(audioUrl)

//         //  downloadFile(audioUrl, 'recording.webm')

//         setAudioChunks([])
//       }
//     }
//   }

//   // function downloadFile(dataUri: any, filename: any) {
//   //   const a = document.createElement('a')
//   //   a.setAttribute('href', dataUri)
//   //   a.setAttribute('download', filename)

//   //   a.click()
//   // }

//   useEffect(() => {
//     getMicrophonePermission()
//   }, [])

//   const getMicrophonePermission = async () => {
//     if ('MediaRecorder' in window) {
//       try {
//         console.log(navigator.mediaDevices)
//         const streamData = await navigator.mediaDevices.getUserMedia({
//           audio: true,
//         })

//         console.log(streamData)
//         setPermission(true)
//         setStream(streamData)
//       } catch (err: any) {
//         alert(err.message)
//       }
//     } else {
//       alert('The MediaRecorder API is not supported in your browser.')
//     }
//   }
// }

export function useRecordingHook() {
  //const [recorder, setRecorder] = useState<any>()

  const currentPatient = useAppSelector((state) => state.lobby.currentPatient)

  // function resetRecording() {
  //   setRecordedAudio(null)
  // }

  const startRecording = () => {
    // navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
    //   const tempRecorder = new MediaRecorder(stream)
    //   setRecorder(tempRecorder)
    //   tempRecorder.addEventListener('dataavailable', (e: any) => {
    //     // console.log(URL.createObjectURL(e.data))
    //     // setData([...data, e.data])
    //     console.log('>>>>> data recieved')
    //     setRecordedAudio(e.data)
    //   })
    //   console.log('>>>>> starting recording')
    //   tempRecorder.start()
    // })

    ;(window as any).ReactNativeWebView?.postMessage(
      JSON.stringify({
        action: 'start',
      }),
    )
  }

  function stopRecording() {
    // console.log('>>>>> stopping recording')
    // recorder.stop()
    // // Remove “recording” icon from browser tab
    // recorder.stream.getTracks().forEach((i: any) => i.stop())

    ;(window as any).ReactNativeWebView?.postMessage(
      JSON.stringify({
        action: 'stop',
      }),
    )
  }

  return {
    startRecording,
    stopRecording,
    // recordedAudio,
    // resetRecording,
  }
}
