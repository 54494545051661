export function convertUtcToEst(utcDate: Date): Date {
  // Use Intl.DateTimeFormat for precise timezone conversion
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/New_York',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  })

  // Convert the UTC date to a localized EST/EDT date string
  const parts = formatter.formatToParts(utcDate)

  // Reconstruct the date using the formatted parts
  const partsMap = Object.fromEntries(parts.map(({ type, value }) => [type, value]))

  // Create a new Date in the EST/EDT timezone
  return new Date(
    `${partsMap.year}-${partsMap.month}-${partsMap.day}T${partsMap.hour}:${partsMap.minute}:${partsMap.second}`,
  )
}
