import { useEffect, useState } from 'react'
import { pollLobbyQueueAsync } from '../redux/lobby'
import { getNotesByStatusAsync } from '../redux/notes'
import { useAppDispatch, useAppSelector } from '../redux/store'

export function useLobbyPolling() {
  const clinicId = useAppSelector((state) => state.bed.clinic?.id)

  const [intervalId, setIntervalId] = useState<any>(null)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!intervalId && clinicId) {
      const intervalIdLocal = setInterval(() => {
        dispatch(pollLobbyQueueAsync({ clinicId: clinicId }))
        dispatch(getNotesByStatusAsync())
      }, 3000)

      setIntervalId(intervalIdLocal)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [intervalId, clinicId])
}

//getNotesByStatusAsync
