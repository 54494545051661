import { Col } from 'antd'
import styled from 'styled-components'

export const Header = styled.div`
  color: #e5e7eb;
  font-size: 13px;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const ResOptions = styled(Col)`
  // width: 100%;
  // height: 100%;
  color: #e5e7eb;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
export const EmptySpan = styled.div`
  width: 100%;
  color: #e5e7eb;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const ResOptionsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
`

export const OptionsContainer = styled.div`
  min-width: 100px;
  display: flex;
`

export const TickImg = styled.img`
  // margin-top: 0.3rem;
`

export const Item = styled.div`
  text-transform: capitalize;
  white-space: nowrap;
`
