import { createSlice } from '@reduxjs/toolkit'

interface BedState {
  bedCode: string
  bedName: string
  clinic: any
}

const initialState: BedState = {
  bedCode: '',
  bedName: '',
  clinic: null,
}

const bedSlice = createSlice({
  name: 'bed',
  initialState,
  reducers: {
    setBed: (state, action) => {
      state.bedName = action.payload.name
      state.bedCode = action.payload.code
      state.clinic = action.payload.clinic
    },
  },
})

export const { setBed } = bedSlice.actions
export default bedSlice.reducer
