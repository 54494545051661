import styled from 'styled-components'

export const TextContent = styled.div`
  color: #e5e7eb;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #2a2d50;
  background: #10123d;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 16px;
`
export const Text = styled.div`
  color: #e5e7eb;
  line-height: normal;
  position: absolute;
  margin-top: -13px;
  margin-left: 20px;
  padding: 12px 16px;
  width: 97px;
  background: #050624;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #2a2d50;
  border-radius: 4px;
  font-weight: 700;
  font-size: 13px;
`
