import { useEffect, useRef, useState } from 'react'
import { Button, Input } from 'antd'
import axios from 'axios'
import styled from 'styled-components'
import { API_URL, AppConstant } from 'AppConstant'
import FullScreen from 'images/fullscreen.svg'
import { notifyError, notifySuccess } from 'utils/Notification'
import Arrow from '../images/arrow.svg'
import KLogo from '../images/initialLogo.svg'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #050624;
`

const LoginBox = styled.div`
  width: 384px;
  height: auto;
  margin-bottom: 150px;
`
const LoginDetails = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
const Logo = styled.img`
  margin-bottom: 66px;
`

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: #007f7c;
  font-size: 20px;
  font-style: normal;
  display: flex;
  align-item: center;
  margin-bottom: 16px;
  font-weight: 800;
  line-height: 130%;
`

const VerifyTitle = styled.div`
  color: #007f7c;
  font-size: 20px;
  font-style: normal;
  display: flex;
  align-item: center;
  margin-bottom: 16px;
  font-weight: 800;
  line-height: 130%;
`
const UserLogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-item: center;
`
const UserLogo = styled.img`
  margin-bottom: 8px;
`

const InputWrapper = styled.div`
  width: 100%;
`

const Label = styled.label`
  color: #e5e7eb;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 2px;
`
const Span = styled.div`
  color: #d2d3e0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;
`
const ResendText = styled.div`
  color: #007f7c;
  margin-top: 4px;
  display: flex;
  justify-content: end;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  cursor: pointer;
`
const BottomText = styled.div`
  color: #007f7c;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  cursor: pointer;
`

const ErrorWrapper = styled.div`
  width: 100%;
  color: #e33939;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  display: inline-block;
  margin-top: 5px;
  margin-left: 2px;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 29px;
`
export enum ErrorStatus {
  error = 'error',
  warning = 'warning',
  emptyString = '',
}

export default function Login({ onAuthComplete }: any) {
  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [errorStatus, setErrorStatus] = useState<ErrorStatus>(ErrorStatus.emptyString)
  const tabRef = useRef<any>(null)
  const [otpSent, setOtpSent] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const inputRef = useRef<any>(null)

  useEffect(() => {
    document.addEventListener('focusout', function (e) {
      window.scrollTo(0, 0)
    })

    return () => {
      document.removeEventListener('focusout', function (e) {
        window.scrollTo(0, 0)
      })
    }
  })

  async function sendCode() {
    setButtonLoading(true)
    const formatPhone = `+1${phone.replace(/[^0-9]/gi, '')}`
    if (phone === '') {
      setError('empty_number')
      setErrorStatus(ErrorStatus.error)
      setButtonLoading(false)
    } else if (formatPhone.length < 12) {
      setError('invalid_format')
      setErrorStatus(ErrorStatus.error)
      setButtonLoading(false)
    } else {
      let response = await axios
        .post(API_URL + '/auth/login', {
          phone: formatPhone,
        })
        .then((res) => res)
        .catch((err) => err.response)
      if (response && response.data && response.data.code === 'success') {
        setOtpSent(true)
        setError('')
        setButtonLoading(false)
      } else if (response && response.data && response.data.code === 'user_not_found') {
        setError('no_user')
      } else {
        setButtonLoading(false)
      }
    }
  }

  async function verifyCode() {
    setButtonLoading(true)
    const formatPhone = `+1${phone.replace(/[^0-9]/gi, '')}`
    if (code === '') {
      setError('empty_code')
      setErrorStatus(ErrorStatus.error)
      setButtonLoading(false)
    } else {
      let response = await axios
        .post(API_URL + '/auth/login/verify', {
          phone: formatPhone,
          code,
        })
        .then((res) => res)
        .catch((err) => err.response)
      if (response && response.data && response.data.code === 'invalid_value') {
        setError('invalid_code')
      } else if (response && response.data && response.data.code === 'success') {
        localStorage.setItem(AppConstant.ACCESS_TOKEN, response.data.data.accessToken)

        onAuthComplete()
      }

      setOtpSent(true)
      setButtonLoading(false)
    }
  }

  const enterFullscreen = () => {
    const elem: any = tabRef.current
    if (elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen()
      }
    }
  }

  return (
    <Container ref={tabRef}>
      {/* <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'absolute',
          top: 0,
          marginTop: '61px',
          paddingRight: '44px',
        }}
      >
        <div
          onClick={() => {
            enterFullscreen()
          }}
          style={{
            color: '#007F7C',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <img src={FullScreen}></img>
        </div>
      </div> */}
      <LoginBox>
        <LogoWrapper>
          <Logo src={KLogo}></Logo>
        </LogoWrapper>
        {!otpSent && (
          <LoginDetails
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                sendCode()
              }
            }}
          >
            <InputWrapper>
              <Label>Mobile</Label>
              <Input
                value={phone}
                status={errorStatus}
                inputMode="numeric"
                onChange={(e) => {
                  const formattedValue = e.target.value
                    .replace(/\D/g, '')
                    .slice(0, 10)
                    .replace(/(\d{3})(\d{1,3})?(\d{0,4})?/, (_: any, first: any, second: any, third: any) => {
                      let result = first
                      if (second) result += '-' + second
                      if (third) result += '-' + third
                      return result
                    })
                  setPhone(formattedValue)
                  setError('')
                  setErrorStatus(ErrorStatus.emptyString)
                }}
                placeholder="Enter mobile number"
              ></Input>

              {error === 'invalid_format' && <ErrorWrapper>Invalid mobile number !</ErrorWrapper>}
              {error === 'empty_number' && <ErrorWrapper>Please enter mobile number !</ErrorWrapper>}
              {error === 'no_user' && <ErrorWrapper>User not found !</ErrorWrapper>}
              <ButtonWrapper>
                <Button
                  type="primary"
                  style={{ width: '100%' }}
                  onClick={() => {
                    sendCode()
                  }}
                >
                  Send Passcode
                </Button>
              </ButtonWrapper>
            </InputWrapper>
          </LoginDetails>
        )}
        {otpSent && (
          <LoginDetails
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                verifyCode()
              }
            }}
          >
            {/* <VerifyTitle>
              <img style={{ marginRight: '4px' }} src={PhoneSvg}></img>
              Verify your mobile number
            </VerifyTitle> */}
            <InputWrapper>
              {/* <Span>Please enter the code sent to your mobile number</Span> */}
              <Label>Verification Code</Label>
              <Input
                value={code}
                status={errorStatus}
                inputMode="numeric"
                onChange={(e) => {
                  setCode(e.target.value)
                  setError('')
                  setErrorStatus(ErrorStatus.emptyString)
                }}
                placeholder="Enter code"
              ></Input>
              {error === 'empty_code' && <ErrorWrapper>Please enter code !</ErrorWrapper>}
              {error === 'invalid_code' && <ErrorWrapper>Invalid code !</ErrorWrapper>}

              <ButtonWrapper>
                <Button type="primary" style={{ width: '100%' }} onClick={verifyCode}>
                  Verify
                </Button>
              </ButtonWrapper>
              <BottomText>
                <div
                  onClick={() => {
                    sendCode()
                  }}
                >
                  Resend OTP <img style={{ marginRight: '2px' }} src={Arrow}></img>
                </div>
                <span
                  onClick={() => {
                    setOtpSent(false)
                    setCode('')
                    setError('')
                    setErrorStatus(ErrorStatus.emptyString)
                  }}
                >
                  {' '}
                  Wrong Number?
                </span>
              </BottomText>
            </InputWrapper>
          </LoginDetails>
        )}
      </LoginBox>
    </Container>
  )
}
